<template>
<div class="container">
  <div class="messageCta" @click="showSpecialMessage = !showSpecialMessage;gTag('clicked_special_message')">
    Click for Special Message to MTurk Workers
  </div>
  <div class="noteContainer" v-if="showSpecialMessage">
      <div class="leftColumn">
        <div class="specialNoteToWorkers">
          Dear beloved micro-workers.&nbsp;&nbsp;Recently, and after I started my HIT, I found the NYTimes article titled, <b>"I Found Work on an Amazon Website.  I Made 97 Cents an Hour"</b> dated Nov. 15, 2019.   
          In response, I have expired my HIT (screenshot) I started earlier this week and restarting with triple Reward from $0.07 to $0.21.&nbsp;&nbsp;&nbsp;For this ~45 second task (based on Google Analytics), that works out to a rate of .28 /min, or $16.80 /hr - no fortune, but better than $5.60 /hr.
          &nbsp;&nbsp;&nbsp;My cost to Amazon also triples.
          &nbsp;&nbsp;I certainly cannot afford these additional costs.

          &nbsp;&nbsp;&nbsp;I am currently unemployed and using these responses to research my prototype.  
          &nbsp;&nbsp;&nbsp;My budget is very small, but I want to do the right thing here.
          &nbsp;&nbsp;&nbsp;I undertand that AI technology will eliminate jobs, and we will need this micro-economy to be around and flourishing to provide new means to work.<br /><br />
          &nbsp;&nbsp;&nbsp;Do you know that your micro-work will make a <b>macro-significant</b> contribution towards AI development.  How do I know?&nbsp;&nbsp;&nbsp;That's exactly why I'm here asking you my questions!&nbsp;&nbsp;&nbsp;Authentic, Real, Quality, Finite, Scarce, Human input will become more valuable as Artificial input from Deep Learning algorithms advance and become the norm.  We should value humanity MORE as AI takes hold, not less.  
          &nbsp;&nbsp;&nbsp;I hope bigger and better-funded Requesters also reflect on the future and see this as an opportunity to lean into this Human, micro-workforce with more investment, and build on Brand.  We are getting a good ROI here.&nbsp;&nbsp;In exchange, if High-Quality Human input is provided by this community, then Your Product stays valuable and everyone will benefit.<br /><br />
          &nbsp;&nbsp;&nbsp;Sorry for the long message, but needed to communicate this to you all; especially after reading that article and understanding that this work IS the future - just a matter of time.  Why wait around when you can lean into it and help foster it.  <u>I'm not affiliated with anyone but myself.</u>  I have extended the HIT duration to account for time spent reading this message.

          <br /><br />
          Any questions, please reach out.  Thank you for being here,<br /><br />Wayne Anderson,<br />Kansas City, Missouri <br /><br />   <i>Now, please kindly answer my questions about you, and do not let this message influence your responses!! :) &nbsp;</i><img src="@/assets/heart.png" width="17">
        </div>
      </div>
      <div class="rightColumn">
        <div>
          <img src="@/assets/mturk_special_note_to_workers.png">
        </div>
      </div>
      

    </div><br /><br /><div class="actualHit">Please be thoughtful and true / Double check your entries - no misspellings, please!!</div>
  <v-app>
  

<form @submit="submit" ref="form" action="https://www.mturk.com/mturk/externalSubmit" method="POST">
  <input type="hidden" id="assignmentId" name="assignmentId" :value="this.$route.query.assignmentId">
  <input type="hidden" id="meanStatement" name="meanStatement" :value="this.answer1">
  <input type="hidden" id="endStatement" name="endStatement" :value="this.answer2">
  <input type="hidden" id="valueStatement" name="valueStatement" :value="this.answer3">
  <input type="hidden" id="nextStep" name="nextStep" :value="this.answer7">
  <input type="hidden" id="meanStatementStimulusText" name="meanStatementStimulusText" :value="this.answer4">
  <input type="hidden" id="endStatementStimulusText" name="endStatementStimulusText" :value="this.answer5">
  <input type="hidden" id="valueStatementStimulusText" name="valueStatementStimulusText" :value="this.answer6">
  <input type="hidden" id="nextStepStimulusText" name="nextStepStimulusText" :value="this.answer8">
  <v-stepper v-model="e6" vertical non-linear>
    <v-stepper-step 
      :complete="e6 > 1"
      step="1" 
      editable>Consider your short and long-term goals:&nbsp;&nbsp; <b>{{this.answer1}}</b>
      <!--<small>Think of something you have been meaning to do for awhile</small>-->
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card color="grey lighten-3" class="mb-2" height="180px">
        <v-layout row wrap>
            <v-flex xs12 class="text-xs-center" mt-4>
                <h2>What behavior, or action, do you wish to start doing?</h2>
            </v-flex>
            <v-flex xs12 sm8 offset-sm2 mt-1 mb-2>
                    <v-layout column>
                        <v-flex>
                            <v-text-field
                                ref="statement_input1"
                                name="answer1"
                                :hint="this.meanStatementStimulusHint"
                                id="answer1"
                                :rules="rules"
                                counter="60"
                                box
                                clearable
                                type="text"
                                v-model="answer1"
                                @mouseover="giveFocus"
                                autofocus
                                required></v-text-field>
                        </v-flex>
                    </v-layout>
            </v-flex>
        </v-layout>
      
      </v-card>
      <v-layout>
        <v-flex mb-3>
          <v-btn color="primary" @click="continue1">Click for Next Question</v-btn>
        </v-flex>
      </v-layout>
      
    </v-stepper-content>
    
    <v-stepper-step :complete="e6 > 2" step="2" editable>Rationale for action:&nbsp;&nbsp; <b><i>{{this.answer2}}</i></b></v-stepper-step>

    <v-stepper-content step="2">
      <v-card color="grey lighten-3" class="mb-2" height="180px">
        <v-layout row wrap>
            <v-flex xs12 class="text-xs-center" mt-4>
                <h2>Why? What result are you trying to achieve?</h2>
            </v-flex>
            <v-flex xs12 sm8 offset-sm2 mt-3 mb-4>
                    <v-layout column>
                        <v-flex>
                            <v-text-field
                                ref="statement_input2"
                                name="answer2"
                                :hint="this.endStatementStimulusHint"
                                box
                                clearable
                                :rules="rules"
                                counter="60"
                                id="answer2"
                                type="text"
                                v-model="answer2"
                                required
                                autofocus
                                @mouseover="giveFocus">
                            </v-text-field>
                        </v-flex>
                    </v-layout>
            </v-flex>
        </v-layout>
      </v-card>
      <v-layout>
        <v-flex mb-3>
          <v-btn color="primary" @click="continue2">Click for Next Question</v-btn>
        </v-flex>
      </v-layout>
    </v-stepper-content>

    <v-stepper-step :complete="e6 > 3" step="3" editable>How it matters:</v-stepper-step>

      <v-stepper-content step="3">
        <v-card color="grey lighten-3" class="mb-2" height="180px">
          <v-layout row wrap>
              <v-flex xs12 class="text-xs-center" mt-4>
                  <h2>What happens if you DO NOT do this?</h2>
              </v-flex>
              <v-flex xs12 sm8 offset-sm2 mt-3 mb-4>
                      <v-layout column>
                          <v-flex>
                              <v-text-field
                                  ref="statement_input3"
                                  name="answer3"
                                  :hint="this.valueStatementStimulusHint"
                                  box
                                  clearable
                                  :rules="rules"
                                  counter="60"
                                  id="answer3"
                                  type="text"
                                  v-model="answer3"
                                  required
                                  autofocus
                                  @mouseover="giveFocus">
                              </v-text-field>
                          </v-flex>
                      </v-layout>
                  
              </v-flex>
          </v-layout>
        </v-card>
        <v-layout>
          <v-flex mb-3>
            <v-btn color="primary" @click="continue3">Click for Next Question</v-btn>
          </v-flex>
        </v-layout>
      </v-stepper-content>
      <v-stepper-step :complete="e6 > 4" step="4" editable>Gain traction towards your goal:</v-stepper-step>
        <v-stepper-content step="4">
          <v-card color="grey lighten-3" class="mb-4" height="200px">
            <v-layout row wrap>
                <v-flex xs12 class="text-xs-center" mt-4>
                    <h2>What is a specific, next action step you could take towards this goal?</h2>
                </v-flex>
                <v-flex xs12 sm8 offset-sm2 mt-3 mb-4>
                        <v-layout column>
                            <v-flex>
                                <v-text-field
                                    ref="statement_input7"
                                    name="answer7"
                                    :hint="this.nextStepStimulusHint"
                                    box
                                    clearable
                                    :rules="rules"
                                    counter="60"
                                    id="answer7"
                                    type="text"
                                    v-model="answer7"
                                    required
                                    autofocus
                                    @mouseover="giveFocus">
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    
                </v-flex>
            </v-layout>
          </v-card>
          <v-layout>
            <v-flex mb-3>
              <v-btn color="primary" type="submit">Submit</v-btn>
            </v-flex>
          </v-layout>
        </v-stepper-content>


     </v-stepper>
    </form>


    
</v-app>
   
  </div>
</template>

<script>
import '@/plugins/vuetify'
import minderModule from '@/store/minder'
import { mapGetters, mapActions } from 'vuex'

  export default {
    data () {
      return {
        e6: 1,
        answer1: '',
        answer2: '',
        answer3: '',
        answer4: '',
        answer5: '',
        answer6: '',
        answer7: '',
        answer8: '',
        assignmentId: null,
        showSpecialMessage: false,
        rules: [
          (v) => !!v || 'Statement is required',
          (v) => (v && v.length <= 60) || 'Max 60 characters'
        ]
      }
    },
    computed: {
      ...mapGetters('minder', {
        meanStatement: 'meanStatement',
        endStatement: 'endStatement',
        valueStatement: 'valueStatement'
      }),
      ...mapGetters('cms', [
        'meanStatementStimulusText',
        'endStatementStimulusText',
        'valueStatementStimulusText',
        'nextStepStimulusText',  //NEW
        'meanStatementStimulusHint',
        'endStatementStimulusHint',
        'valueStatementStimulusHint',
        'nextStepStimulusHint',  //NEW
        'meanStatementStepText',
        'endStatementStepText',
        'valueStatementStepText',
        'nextStepStepText'  //NEW
      ]),
    },
    methods: {
      ...mapActions('minder', {
        setMeanStatement: 'setMeanStatement',
        resetStatements: 'resetStatements',
        setEndStatement: 'setEndStatement',
        setValueStatement: 'setValueStatement',
        addMinder: 'addMinder',
        createMinder: 'create',
        setStimulusText: 'setStimulusText'
      }),

      

      continue1 () {
        if (this.$refs.statement_input1.validate()) {
          this.setMeanStatement(this.answer1)
          this.e6 = 2
          this.$refs.statement_input2.focus()
        }
      },
      continue2 () {
        if (this.$refs.statement_input2.validate()) {
          this.setEndStatement(this.answer2)
          this.e6 = 3
          this.$refs.statement_input3.focus()
        }
      },
      continue3 () {
        if (this.$refs.statement_input3.validate()) {
          this.setValueStatement(this.answer3)
          this.e6 = 4
          this.$refs.statement_input7.focus()
        }
      },
      async submit () {
        try {
          
            this.e6 = 5
          
        } catch (error) {
          alert(error)
        }
      },
      giveFocus () {
        this.$refs.statement_input1.focus()
      }

    },
    beforeCreate () {
      if(!this.$store.state.minder) this.$store.registerModule('minder', minderModule)
    },
    created () {
      this.resetStatements()
      //Load stimulus text (the questions)
      this.answer4 = this.meanStatementStimulusText
      this.answer5 = this.endStatementStimulusText
      this.answer6 = this.valueStatementStimulusText
      this.answer8 = this.nextStepStimulusText
    },
    mounted () {
      this.resetStatements()
      //Load stimulus text (the questions)
      this.answer4 = this.meanStatementStimulusText
      this.answer5 = this.endStatementStimulusText
      this.answer6 = this.valueStatementStimulusText
      this.answer8 = this.nextStepStimulusText

     
    }
  }
</script>

<style>
  .container {
    margin: 0 auto;
  }

  .noteContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 5px;
    max-width: 1400px;
    margin: 0 auto 10px;
    color: #fff;
  }

  .messageCta {
    background-color: red;
    width: 200px;
    color: yellow;
    padding: 5px;
    border: 1px solid rgb(250, 213, 6),
  }

  .title {
    font-size: .7em;
  }
  .actualHit {
    color: white;
    font-size: 1.2em;
  }

  .specialNoteToWorkers {
    padding: 10px;
    font-size: 1.1em;
  }

  .specialNoteToWorkers * a {
    color: white;
    text-decoration: none;
  }

  .rightColumn * img {
    padding: 10px;
    object-fit: cover;
    width: 100%;
  }




  div.v-stepper__label {
    font-size: 17px;
  }
  div.v-stepper__label small {
    font-size: 14px;
  }
  #lang-container {
    display: block;
  }
</style>